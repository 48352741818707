import React, { useEffect, useRef, Fragment } from 'react';
import { MapContainer, Polyline, LayersControl, LayerGroup, Marker, Popup, CircleMarker, useMap, useMapEvents } from 'react-leaflet';
import Box from '@mui/material/Box';
// import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

import { format } from 'date-fns';
import Basemaps from './Basemaps';
import SchoolMarker from './SchoolMarker';
import { phuThienCoord as phuThien } from '../../data/geoCoding';

const Heatmap = ({ dataArray, plotline, plotheat, plotCombined, calcD }) => {
  // console.log(dataArray);
  const { Overlay } = LayersControl;

  const plotlineTrue = plotline > 0 ? true : false;
  const plotHeatTrue = plotheat > 0 ? true : false;
  const plotCombinedTrue = plotCombined > 0 ? true : false;

  // Custom hook to fit the map bounds to all polylines
  const FitBoundsPolylines = ({ polylines }) => {
    const map = useMap();
    // console.log(polylines);
    useEffect(() => {
      if (polylines.length > 0) {
        const bounds = L.latLngBounds(polylines.flat());
        map.fitBounds(bounds);
      }
    }, [polylines, map]);

    return null;
  };
  const getLatLongOnly = (obj) => {
    let _latLongArr = {};
    Object.keys(obj).map((key) => {
      const obj1 = obj[key];
      if (!_latLongArr[key]) _latLongArr[key] = {};
      Object.keys(obj1).map((key1) => {
        const obj2 = obj1[key1];
        if (obj2.length > 2) {
          if (!_latLongArr[key][key1]) _latLongArr[key][key1] = [];
          _latLongArr[key][key1] = obj2.map((e) => [e.Lat.toFixed(4), e.Long.toFixed(4)]);
        }
        return null;
      });
    });
    // console.log(_latLongArr);
    return _latLongArr;
  };
  const latLongOnly = getLatLongOnly(dataArray);
  const combineLatLong2 = (arr) => {
    const latLongArr = [];
    arr.forEach((e) => {
      latLongArr.push([e.Lat, e.Long]);
    });
    return latLongArr;
  };
  const combinedLatLong = (data) => {
    const latLong = {};
    Object.keys(data).forEach((key) => {
      if (!latLong[key]) {
        latLong[key] = [];
      }
      data[key].forEach((e) => {
        latLong[key].push([Number.parseFloat(e.Lat).toFixed(5), Number.parseFloat(e.Long).toFixed(5)]);
      });
    });
    return latLong;
  };
  const combinedData = (data) => {
    const combinedData = {};

    for (const entity in data) {
      for (const date in data[entity]) {
        if (!combinedData[date]) {
          combinedData[date] = [];
        }
        combinedData[date] = combinedData[date].concat(data[entity][date]);
      }
    }
    return combinedData;
  };

  const dayOfWeek = (date) => {
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekday[new Date(date).getDay()];
  };

  // const polyData = plotlineTrue ? setLatLong(_dataArray) : null;
  // const _dataArray = extractData(dataArray);
  // const pathColor = ['red', 'brown', 'orange', 'purple', 'blue', 'green', 'navy'];
  const pathColor = [
    '#ea5545',
    '#f46a9b',
    '#ef9b20',
    '#edbf33',
    '#ede15b',
    '#bdcf32',
    '#87bc45',
    '#27aeef',
    '#b33dc6',
    '#e60049',
    '#0bb4ff',
    '#50e991',
    '#e6d800',
    '#9b19f5',
    '#ffa300',
    '#dc0ab4',
    '#b3d4ff',
    '#00bfa0',
  ];
  const _combinedData = plotCombinedTrue ? combinedData(dataArray) : null;
  const _combinedLatLong = plotCombinedTrue ? combinedLatLong(_combinedData) : null;
  // console.log(_combinedLatLong);

  // const _iconHoangSa = new L.DivIcon({
  //   html: '<div style="font-weight:bold;color:grey;width:100px;text-align:center">Quần đảo<br/>Hoàng sa</div>',
  //   className: '',
  // });
  // const _iconTruongSa = new L.DivIcon({
  //   html: '<div style="font-weight:bold;color:grey;width:150px;text-align:center;">Quần đảo<br/>Trường Sa<br/>(Việt Nam)</div>',
  //   className: '',
  // });
  // const _icon_zoom = new L.DivIcon({
  //   html: '<div style="width:150px;height:100px;background:#d4dadc;"></div>',
  //   className: '',
  // });
  var LeafIcon = L.Icon.extend({
    options: {
      iconSize: [25, 40],
      iconAnchor: [12, 41],
      popupAnchor: [0, -40],
    },
  });
  var startIcon = new LeafIcon({ iconUrl: '../../assets/start2.png' }),
    finishIcon = new LeafIcon({ iconUrl: '../../assets/finish2.png' });

  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Lon: school[3],
          address: school[4],
          // ward: `${subarea.name} (${subarea.id})`,
          // district: `${area.name} (${area.id})`,
          // city: `${city.name} (${city.id})`,
          // startDate: school[8], // Initialize start and end dates
          // endDate: school[9],
        }));
      })
    )
  );
  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        center={phuThien.center}
        zoom={12}
        minZoom={10}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user')).username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }>
        <LayersControl position='topright'>
          {Object.keys(dataArray).map((key) => {
            const dataArray1 = dataArray[key];
            return Object.keys(dataArray1).map((key1) => {
              const latLongArray2 = latLongOnly[key][key1];
              const _color = pathColor[Math.floor(Math.random() * 18)];
              return (
                <Overlay name={`Device ${key} Day ${key1}`} key={`OVL-${key}-${key1}`} checked>
                  <LayerGroup>
                    <Polyline positions={latLongArray2} pathOptions={{ color: 'grey', opacity: 0.5, weight: 3 }} />
                    {latLongArray2.map((item, index2) => {
                      const { Speed, time } = dataArray1[key1][index2];
                      const speedInKmh = (Speed * 1.852).toFixed(2); // Convert knots to km/h and round to 2 decimal places
                      const speedInMs = (Speed * 0.514444).toFixed(2); // Convert knots to m/s and round to 2 decimal places
                      const isStartOrFinish = index2 === 0 ? 'Start' : index2 === latLongArray2.length - 1 ? 'Finish' : null;
                      const icon = isStartOrFinish === 'Finish' ? finishIcon : startIcon;

                      return (
                        <Fragment key={`${key}-${key1}-${index2}`}>
                          {isStartOrFinish ? (
                            <Marker position={item} icon={icon}>
                              {/* <Popup>
                                <strong>{isStartOrFinish} point</strong>
                                <br />
                                {index2} - {format(new Date(time), 'HH:mm:ss')} - {speedInKmh} km/h
                              </Popup> */}
                            </Marker>
                          ) : (
                            <CircleMarker center={item} color={'grey'} radius={3} pathOptions={{ stroke: false, fillOpacity: 0.3 }}>
                              {/* <Popup>
                                <strong>{index2}</strong> - {format(new Date(time), 'HH:mm:ss')} - {speedInKmh} km/h ( {speedInMs} m/s )
                                <br />[{item[0]}, {item[1]}]
                              </Popup> */}
                            </CircleMarker>
                          )}
                          {/* <CircleMarker center={item} fillOpacity={0.5} /> */}
                        </Fragment>
                      );
                    })}
                  </LayerGroup>
                </Overlay>
              );
            });
          })}

          {/* {Object.keys(_combinedData).map(
          (key, index) =>
            // <Overlay name={dayOfWeek(key) + ' ' + key + ' - Heatmap'} key={index} checked={plotHeatTrue ? true : false}>
            plotHeatTrue && (
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={_combinedData[key]}
                longitudeExtractor={(point) => parseFloat(point.Long)}
                latitudeExtractor={(point) => parseFloat(point.Lat)}
                intensityExtractor={(point) => parseFloat(1)}
                // max={4}
                // radius={5} //default radius 30
                // blur={5} //default blur 15
                opacity={0}
                key={index}
              />
            )

          // </Overlay>
        )} */}

          <Overlay name={'Các trường THCS'} key={'schools'} checked>
            <LayerGroup>
              {schools.map((school, idx) => (
                <SchoolMarker key={idx} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
              ))}
            </LayerGroup>
          </Overlay>
          <Basemaps />
        </LayersControl>
        <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} />
      </MapContainer>
    </Box>
  );
};

export default Heatmap;

{
  /* {places.map((place, index) => showBoundingBoxes({ place }, index))} */
}

{
  /* {Object.keys(_combinedLatLong)
    .sort((b, a) => {
      return new Date(a) - new Date(b);
    })
    .map((key, index) => (
      <Overlay name={dayOfWeek(key) + ' ' + key + ' - Polyline'} key={index} checked={plotlineTrue ? true : false}>
        <LayerGroup>
          <Polyline positions={_combinedLatLong[key]} color={pathColor[index % 7]} />
          {_combinedLatLong[key].map((item, index) => {
            if (index === 0) {
              return (
                <Marker position={item} key={index} icon={startIcon}>
                  <Popup>Start point</Popup>
                </Marker>
              );
            } else if (index === _combinedLatLong[key].length - 1) {
              return (
                <Marker position={item} key={index} icon={finishIcon}>
                  <Popup>Finish point</Popup>
                </Marker>
              );
            } else {
              return <CircleMarker key={index} center={item} radius={3} color='black' weight={1} opacity={0.25} fillColor />;
            }
          })}
        </LayerGroup>
      </Overlay>
    ))} */
}

{
  /*const showBoundingBoxes = ({ place }, index) => {
  const [lat0, lat1, long0, long1] = place.boundingbox.map(Number);
  const bBoxCorners = [
    [lat0, long0],
    [lat0, long1],
    [lat1, long1],
    [lat1, long0],
    [lat0, long0], // Close the loop
  ];

  return <Polyline positions={bBoxCorners} key={index} />;
};*/
}
