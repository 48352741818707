import React, { useEffect, useRef } from 'react';
import { MapContainer, LayersControl, LayerGroup, Marker, CircleMarker, useMap, useMapEvents } from 'react-leaflet';
import Box from '@mui/material/Box';

// import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import HeatmapLayerFactory from '@vgrid/react-leaflet-heatmap-layer/cjs/HeatmapLayer';
import Basemaps from './Basemaps';
import SchoolMarker from './SchoolMarker';
import { phuThienCoord as phuThien } from '../../data/geoCoding.js';

const Heatmapv3 = ({ dataArray }) => {
  const plotOpacity = 0.75;
  const { Overlay } = LayersControl;
  const mapRef = useRef();
  const HeatmapLayer = HeatmapLayerFactory();

  useEffect(() => {
    const handleResize = () => {
      if (mapRef.current) {
        mapRef.current.invalidateSize();
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // Custom hook to fit the map bounds to all polylines
  const FitBoundsPolylines = ({ polylines }) => {
    const map = useMap();
    // console.log(polylines);
    useEffect(() => {
      if (polylines.length > 0) {
        const bounds = L.latLngBounds(polylines.flat());
        map.fitBounds(bounds);
      }
    }, [polylines, map]);

    return null;
  };
  // const getLatLongOnly = (obj) => {
  //   let _latLongArr = {};
  //   Object.keys(obj).map((key) => {
  //     const obj1 = obj[key];
  //     if (!_latLongArr[key]) _latLongArr[key] = {};
  //     Object.keys(obj1).map((key1) => {
  //       const obj2 = obj1[key1];
  //       if (obj2.length > 2) {
  //         if (!_latLongArr[key][key1]) _latLongArr[key][key1] = [];
  //         _latLongArr[key][key1] = obj2.map((e) => [e.Lat.toFixed(4), e.Long.toFixed(4)]);
  //       }
  //     });
  //   });
  //   // console.log(_latLongArr);
  //   return _latLongArr;
  // };
  // const latLongOnly = getLatLongOnly(dataArray);

  const combinedLatLong = (data) => {
    const latLong = {};
    Object.keys(data).forEach((key) => {
      if (!latLong[key]) {
        latLong[key] = [];
      }
      data[key].forEach((e) => {
        latLong[key].push([Number.parseFloat(e.Lat).toFixed(5), Number.parseFloat(e.Long).toFixed(5)]);
      });
    });
    return latLong;
  };
  const combinedData = (data) => {
    const combinedData = {};

    for (const entity in data) {
      for (const date in data[entity]) {
        if (!combinedData[date]) {
          combinedData[date] = [];
        }
        combinedData[date] = combinedData[date].concat(data[entity][date]);
      }
    }
    return combinedData;
  };

  const _combinedData = combinedData(dataArray);
  // console.log(_combinedData);
  const _combinedLatLong = combinedLatLong(_combinedData);

  const _iconHoangSa = new L.DivIcon({
    html: '<div style="font-weight:bold;color:grey;width:100px;text-align:center">Quần đảo<br/>Hoàng sa</div>',
    className: '',
  });
  const _iconTruongSa = new L.DivIcon({
    html: '<div style="font-weight:bold;color:grey;width:150px;text-align:center;">Quần đảo<br/>Trường Sa<br/>(Việt Nam)</div>',
    className: '',
  });
  const _icon_zoom = new L.DivIcon({
    html: '<div style="width:150px;height:100px;background:#d4dadc;"></div>',
    className: '',
  });
  // var LeafIcon = L.Icon.extend({
  //   options: {
  //     iconSize: [25, 40],
  //     iconAnchor: [12, 41],
  //     popupAnchor: [0, -40],
  //   },
  // });
  const dayOfWeek = (date) => {
    const weekday = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    return weekday[new Date(date).getDay()];
  };
  const schools = JSON.parse(localStorage.getItem('schoolData')).flatMap((city) =>
    city.areas.flatMap((area) =>
      area.subareas.flatMap((subarea) => {
        return subarea.schools.map((school) => ({
          id: school[0],
          schoolName: school[1],
          Lat: school[2],
          Lon: school[3],
          address: school[4],
          // ward: `${subarea.name} (${subarea.id})`,
          // district: `${area.name} (${area.id})`,
          // city: `${city.name} (${city.id})`,
          // startDate: school[8], // Initialize start and end dates
          // endDate: school[9],
        }));
      })
    )
  );
  return (
    <Box width={'100%'} height={'100%'} id='map'>
      <MapContainer
        center={phuThien.center}
        zoom={13}
        minZoom={10}
        maxZoom={18}
        maxBoundsViscosity={1.0}
        maxBounds={
          JSON.parse(localStorage.getItem('user')).username === process.env.REACT_APP_SUPER_ADMIN
            ? null
            : L.latLngBounds(phuThien.sW, phuThien.nE)
        }
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}>
        <LayersControl position='topright'>
          {Object.keys(_combinedData).map((key, index) => (
            <Overlay name={dayOfWeek(key) + ' ' + key + ' - Heatmap'} key={index} checked>
              <HeatmapLayer
                fitBoundsOnLoad
                fitBoundsOnUpdate
                points={_combinedData[key]}
                longitudeExtractor={(point) => parseFloat(point.Long)}
                latitudeExtractor={(point) => parseFloat(point.Lat)}
                intensityExtractor={(point) => parseFloat(1)}
                max={4}
                radius={5} //default radius 30
                blur={5} //default blur 15
                opacity={plotOpacity}
                key={index}
              />
            </Overlay>
          ))}

          <Overlay name={'Các trường THCS'} key={'schools'} checked>
            <LayerGroup>
              {schools.map((school, idx) => (
                <SchoolMarker key={idx} position={[school.Lat, school.Lon]} address={school.address} name={school.schoolName} />
              ))}
            </LayerGroup>
          </Overlay>
          <Basemaps />
        </LayersControl>
        <FitBoundsPolylines polylines={Object.values(_combinedLatLong)} />
      </MapContainer>
    </Box>
  );
};

export default Heatmapv3;
