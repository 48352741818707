// SchoolMarker.js
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

// Define a custom icon for the school marker
const schoolIcon = new L.Icon({
  iconUrl: 'https://cdn-icons-png.flaticon.com/128/7439/7439595.png', // Replace with your icon URL
  iconSize: [25, 25],
  iconAnchor: [12, 24],
  popupAnchor: [0, -24],
});

const SchoolMarker = ({ position, name, address }) => {
  return (
    <Marker
      position={position}
      icon={schoolIcon}
      eventHandlers={{
        mouseover: (event) => {
          event.target.openPopup();
        },
        mouseout: (event) => {
          event.target.closePopup();
        },
      }}>
      <Popup>
        <b>{name}</b>
        <br />
        <span style={{ marginTop: '6px' }}>{address}</span>
      </Popup>
    </Marker>
  );
};

export default SchoolMarker;
