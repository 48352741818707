import { useState } from 'react';
import { Box, useTheme, Typography, Button, Popover } from '@mui/material';
import { tokens } from '../theme';
// import ProgressCirle from './ProgressCirle';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';

// const StatBox = ({ title, subtitle, icon, progress, increase }) => {
const StatBox = ({ title, subtitle, icon, figure, unit, tutorial }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box width='100%' m='0 15px' display='flex' flexDirection='column'>
      <Box sx={{ position: 'absolute', top: '0', right: '0' }}>
        <Button onClick={handleClick} sx={{ width: '32px', minWidth: '32px', color: colors.grey[100] }}>
          <HelpIcon />
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          slotProps={{
            paper: {
              style: { width: '250px' },
            },
          }}>
          <Typography fontSize={'16px'} sx={{ p: 2 }}>
            <InfoIcon sx={{ mr: '10px', verticalAlign: 'text-top' }} />
            <strong>{tutorial.title}</strong>
            <br />
            {tutorial.desc}
          </Typography>
        </Popover>
      </Box>
      <Box width={'100%'} display={'flex'}>
        <Box>{icon}</Box>
      </Box>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Typography variant='h4' sx={{ color: colors.grey[100] }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography variant='h4' sx={{ color: colors.grey[100] }}>
            {figure}
          </Typography>
          {/* <ProgressCirle progress={progress} /> */}
        </Box>
      </Box>
      <Box display='flex' justifyContent='space-between' mt='2px'>
        <Typography variant='h5' sx={{ color: colors.greenAccent[300] }}>
          {subtitle}
        </Typography>
        <Typography variant='h5' sx={{ color: colors.greenAccent[300] }}>
          {/* {increase} */}
          {unit}
        </Typography>
      </Box>
    </Box>
  );
};

export default StatBox;
